import * as React from 'react';

import coventry from "../../images/DFH-Coventry-Homes.svg"
import craft from "../../images/DFH-Craft-Homes.svg"
import golden from "../../images/DFH-Logo-Golden-Dog-Registered.svg"
import jet from "../../images/DFH-Jet.svg"

const OurFamily: React.FC = () => {

	return (
		<section className={'my-20 px-5'}>
			<div className={'container mx-auto flex flex-col gap-8'}>
				<h2 className="pb-8 text-center font-kiona text-4xl  font-normal text-secondary max-sm:text-3xl">
					OUR FAMILY OF COMPANIES
				</h2>
				<div
					className={
						'grid grid-cols-4 items-center gap-8 gap-y-12 max-lg:grid-cols-2 max-md:grid-cols-1'
					}
				>
					<img src={coventry} alt="" className="mx-auto" />
					<img src={craft} alt="" className="mx-auto" />
					<img src={golden} alt="" className="mx-auto" />
					<img src={jet} alt="" className="mx-auto" />
				</div>
			</div>
		</section>
	);
};

export default OurFamily;
