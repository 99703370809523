import * as React from 'react';

import placeholder from '../../images/DFH-placeholder.png';

const OurMission: React.FC = () => {
	return (
		<section className={'my-20 px-5'}>
			<div className={'container mx-auto'}>
				<h2 className="py-8 text-center font-kiona text-4xl font-normal text-secondary max-sm:text-3xl">
					OUR MISSION AND VALUES
				</h2>
				<div
					className={
						'grid grid-cols-2 justify-between gap-6 max-lg:grid-cols-1'
					}
				>
					<div className="flex w-full flex-col gap-6 bg-primary p-8 ">
						<h2 className="font-kiona text-4xl font-normal text-secondary max-sm:text-3xl">
							OUR MISSION
						</h2>
						<p className="text-lg leading-normal tracking-wide text-white">
							We believe that owning a dream home should always be within reach, and that is why we are dedicated to crafting homes that provide better lives, contributing to a happier world. With a commitment to beauty, personalization, and attainability, we strive to go beyond constructing houses, fostering a sense of joy and fulfillment in every home we build. Welcome to a world where the journey to a better life begins at home.
						</p>
					</div>
					<div className="flex  w-full  flex-col gap-6 bg-[#F1F1F1] p-8 ">
						<h2 className="font-kiona text-4xl font-normal text-secondary max-sm:text-3xl">
							OUR VALUES
						</h2>
						<p className="text-lg leading-normal tracking-wide text-[#13334C]">
							We are focused on remaining agile while optimizing
							our delivery to our customers. Customers at every
							price point have an opportunity to select unique
							design finishes to add a personal touch to their
							home.
						</p>
						<p className="text-lg leading-normal tracking-wide text-[#13334C]">
							Dream Finders Homes is committed to ESG Initiatives:
						</p>
						<ul className="list-disc pl-10">
							<li className="text-lg leading-normal tracking-wide text-[#13334C]">
								100% of our homes are energy efficient since
								2021.
							</li>
							<li className="text-lg leading-normal tracking-wide text-[#13334C]">
								We are dedicated to helping our communities’
								front-line workers and hometown heroes by
								providing special homebuying initiatives.
							</li>
							<li className="text-lg leading-normal tracking-wide text-[#13334C]">
								We are committed to gender and race equality
								within Dream Finders and at the board level.
							</li>
						</ul>
					</div>
				</div>
				{/* <img src={placeholder} alt="Placeholder" className="w-full" /> */}
			</div>
		</section>
	);
};

export default OurMission;
