import React from 'react';
import Layout from '../layouts/Layout';
import Map from '../components/Map/Map';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import HeroImage from '../components/HeroImage/HeroImage';
import IndexHeroImg from '../images/DFH-hero.png';
import AboutUs from '../components/AboutUs/AboutUs';
import Sponsorships from '../components/Sponsorships/Sponsorships';
import Opportunities from '../components/Opportunities/Opportunities';
import OurFamily from '../components/OurFamlity/OurFamily';
import OurMission from '../components/OurMission/OurMission';

const IndexPage = (props: PageProps) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	return (
		<Layout
			title={'Dream Finders Homes: Careers'}
			desc={'Dream Finders Homes: Careers'}
			location={props.location}
		>
			<HeroImage imgSrc={IndexHeroImg} />
			<OurFamily />
			<AboutUs />
			<Map data={data} />
			<Sponsorships />
			{/* <Opportunities /> */}
			<OurMission />
		</Layout>
	);
};

export default IndexPage;
