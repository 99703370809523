import * as React from 'react';
interface HeroImageProps {
	imgSrc: string;
}
const HeroImage: React.FC<HeroImageProps> = (props: HeroImageProps) => {
	return (
		<section
			className={
				'flex min-h-[472px] flex-col items-center justify-center gap-6 bg-primary bg-cover bg-top px-5 md:min-h-[472px]'
			}
			style={{ backgroundImage: `url('${props.imgSrc}')`, backgroundPosition: "25% 75%" }}
		>
			<div className="container mx-auto flex flex-col items-center justify-center gap-6">
				<h1 className="text-center font-kiona text-4xl font-normal leading-snug text-white">
					<span className="whitespace-nowrap">BUILD</span>
					<span className="font-better-land text-[54px]"> Your</span>
					<span className="whitespace-nowrap"> DREAM CAREER</span>
				</h1>
				<a className="main-button" href="/careers/">
					VIEW CAREER OPPORTUNITIES
				</a>
			</div>
		</section>
	);
};

export default HeroImage;
