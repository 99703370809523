import * as React from 'react';
import builder from '../../images/DFH-Builder100-logo.png';
import fortune from '../../images/DFH-fortune.svg';
import top20 from '../../images/DFH-top20.png';
import VideoPopup from '../VideoPopUp/VideoPopUp';
import beyondBuilding from '../../images/beyond-building-explore.png';

const AboutUs: React.FC = () => {
	return (
		<section className={'my-20 px-5'}>
			<div
				className={
					'container mx-auto grid grid-cols-2 justify-between gap-6 gap-y-12 max-lg:grid-cols-1'
				}
			>
				<div className="flex w-full  flex-col gap-6">
					<h2
						className={
							'font-better-land text-[54px] font-normal text-[#13334C]'
						}
					>
						About Us
					</h2>
					<div className="flex flex-col gap-4">
						<p className="font-lato text-lg text-body">
							As a publicly traded and locally operated company,
							Dream Finders Homes has a commitment to deliver our
							customers the highest standards in a new home. We
							are confident that our design features will exceed
							expectations and surpass the competition. We empower
							our customers to personalize their new dream home
							with finishes that reflect their own taste and
							lifestyle. We hope you will join us in providing the
							“Dream Finders Difference” to our customers.
						</p>
						<p className="font-lato text-lg text-body">
							Dream Finders Homes is defining the future of new
							home construction with its unique designs, superior
							quality materials, strong focus on customer
							satisfaction and an elite desire to be the best home
							builder in America. Dream Finders Homes is your
							dream builder, building the American Dream one home
							at a time.
						</p>
					</div>
				</div>
				<div className="flex h-fit w-full  flex-col gap-6 bg-[#14344D] p-8 ">
					<h2 className="text-center font-kiona text-4xl font-normal text-secondary max-sm:m-0 max-sm:text-3xl">
						AWARDS
					</h2>
					<div className="my-16 grid grid-cols-[auto_auto_auto] flex-wrap items-center justify-center gap-4 max-lg:my-6  max-lg:grid-cols-1 max-sm:mt-0">
						<img
							className="mx-auto"
							src={builder}
							width={166}
							height={48}
							alt="Builder 100 badge"
						/>
						<img
							className="mx-auto w-full max-w-[200px] max-lg:row-start-1"
							src={fortune}
							width={121}
							height={151}
							alt="Fortune 1000 badge"
						/>
						<img
							className="mx-auto"
							src={top20}
							width={166}
							height={48}
							alt="Top 20 badge"
						/>
					</div>
				</div>
			</div>
			<div className="container mx-auto my-12 flex items-center justify-center">
				<VideoPopup
					person={{
						img: beyondBuilding,
						name: 'Beyond Building',
						video: 'https://www.youtube.com/embed/vnlPJw314uE'
					}}
					classList="max-w-none"
				/>
			</div>
		</section>
	);
};

export default AboutUs;
