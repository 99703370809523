import './Map.css';
import * as React from 'react';
import { useState } from 'react';
// @ts-ignore
import { USMap } from '../career-page-components';
import TalentCommunityPopup from '../JobAlerts/TalentCommunityPopUp';
interface mapProps {
	data: any;
}

const CTA: React.FC<mapProps> = (props: mapProps) => {
	const mapOptions = {
		fill: '#C2A01E',
		onHoverFill: '#eaca50',
		disabledFill: '#D2D2D2'
	};

	const [popupOpened, setPopupOpened] = useState(false);

	return (
		<section className={'my-20 px-5 text-primary max-md:hidden'}>
			<div className="container mx-auto py-10">
				<h2 className="text-center font-kiona text-4xl font-normal text-secondary max-sm:text-3xl">
					EXPLORE OPPORTUNITIES BY LOCATION
				</h2>
			</div>
			<div
				className={
					'map container mx-auto bg-[#83B4C4] p-10 text-center max-md:hidden'
				}
			>
				<USMap
					searchPath={'/careers/'}
					options={mapOptions}
					data={props.data}
					noOpeningsCallback={() => {
						setPopupOpened(true);
					}}
				/>
			</div>
			<TalentCommunityPopup
				popupOpened={popupOpened}
				setPopupOpened={setPopupOpened}
			/>
		</section>
	);
};

export default CTA;
